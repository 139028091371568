body {
    --black: #000000;
    --ash-black: #222;
    --white: #fafafa;
    --sky: #00ccff;
    --green: #22dddd;
    --blue: #1300ff;
    --dusk: #6600ff;
    --purple: #9900ff;
    --pink: #ff0066;
    --red: #fe0222;
    --orange: #fd7702;
    --yellow: #ffbb00;
  
    --background: rgba(31, 29, 29, 0.979);
    --accent: var(--black);
  
    margin: 0;
    padding: 0;
    background-color: var(--background);
    color: var(--accent);
  }
  
  * {
    font-family: sofia-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
  }
  
  .container {
    padding: 20px;
  }
  
  
  canvas {
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: pixelated;
    image-rendering: optimize-contrast;
  }

  .container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  
  .popup {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(32, 238, 186, 0.8); /* Slightly transparent white */
    padding: 10px;
    border-radius: 5px;
    border: 2px solid rgba(253, 255, 254, 0.8);
    box-shadow: 0 0 15px rgba(32, 238, 159, 0.8);
    z-index: 1000;
    pointer-events: none;
    opacity: 0; /* Start with invisible */
    transform: scale(0.7); /* Start with a slight scale down */
    transition: opacity 0.8s ease-out, transform 0.8s ease-out; /* Smooth transition */
    backdrop-filter: blur(15px); /* Hologram-like blur */
    width: 200px;
    height: 150px;
    color: var(--white);
  }
  

  .popup.show {
    opacity: 0.9; /* Fade in */
    transform: scale(1); /* Scale to normal size */
    
  }
  
  
  .category-tabs {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
  
  .category-tab {
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    color: white;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .category-tab.active {
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .category-tab:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

