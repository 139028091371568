/* Header.css */

.hero {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #00000075;
  /* background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/public/images/abstract-city.jpg') center/cover no-repeat; */
  height: 10vh; /* Reduced height */
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  z-index: 20;
}


.logo {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2em; /* Reduced font size */
  padding: 20px 10px;
}

.hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center;
  text-align: center;
  height: 100%; /* Ensure full height */
  width: 70%;
}

.hero-content h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5em; /* Reduced font size */
  margin: 5px;
  color: #FFFFFF;
}

.hero-content p {
  font-size: 1.2em; /* Reduced font size */
  max-width: 600px;
  margin: 5px;
  color: #FFFFFF;
}

