/* Default styles for resource pages */

.resource-container {
  position: relative;
  top: 15vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  color: white;
}

img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
}

